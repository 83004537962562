/* src/components/AstrologerSection.css */

.astrologer-section {
  background-color: #f58905;
  padding: 40px;
  text-align: center;
}

.astrologer-slider {
  position: relative;
  overflow: hidden; /* Ensure overflow is hidden for slider */
}

.astrologer-cards-container {
  display: flex;
  flex-direction: column; /* Stack items vertically on smaller screens */
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.astrologer-cards {
  display: flex;
  flex-wrap: nowrap; /* Ensure cards are in a single row */
  overflow-x: auto; /* Allow horizontal scrolling on smaller screens */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  scrollbar-width: thin; /* Adjust scrollbar width as needed */
}

.astrologer-card {
  flex: 0 0 auto;
  width: 250px;
  margin-right: 20px;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.astrologer-card.active {
  opacity: 1;
}

.astrologer-card img {
  width: 100%;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.astrologer-info {
  padding: 10px;
  text-align: left;
}

.slide-controls {
  position: absolute;
  top: 50%;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.slide-controls.left {
  left: 10px;
}

.slide-controls.right {
  right: 10px;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
  .astrologer-cards {
    flex-wrap: wrap;
    justify-content: center;
    overflow-x: hidden; /* Disable horizontal scrolling on smaller screens */
  }

  .astrologer-card {
    margin-bottom: 20px; /* Add margin between cards on smaller screens */
  }
}
