/* src/components/LatestFromBlogSection.css */

.latest-from-blog-section {
  background-color: #f0f0f0;
  padding: 40px;
  text-align: center;
}

.blog-slider {
  position: relative;
}

.blog-cards-container {
  display: flex;
  flex-direction: column; /* Stack items vertically on smaller screens */
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

.blog-cards {
  display: flex;
  flex-wrap: nowrap; /* Ensure cards are in a single row */
  overflow-x: auto; /* Allow horizontal scrolling on smaller screens */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  scrollbar-width: thin; /* Adjust scrollbar width as needed */
}

.blog-card {
  flex: 0 0 auto;
  background-color: white;
  padding: 20px;
  margin-right: 20px; /* Add margin between cards */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.blog-card.active {
  opacity: 1;
}

.blog-card h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.blog-card p {
  font-size: 14px;
  margin: 0;
}

.slide-controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
}

.slide-controls.left {
  left: 10px;
}

.slide-controls.right {
  right: 10px;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
  .blog-cards {
    flex-wrap: wrap;
    justify-content: center;
    overflow-x: hidden; /* Disable horizontal scrolling on smaller screens */
  }

  .blog-card {
    margin-bottom: 20px; /* Add margin between cards on smaller screens */
    width: calc(100% - 20px); /* Adjust card width for smaller screens */
  }
}
