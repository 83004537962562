/* src/App.css */

body {
  margin: 0;
  font-family: "Arial", sans-serif;
  overflow-x: hidden;
}

.navbar {
  background-color: #dbf8f5;
  overflow: hidden;
  height: auto; /* Adjust height for responsiveness */
  padding: 15px; /* Add padding for better spacing on smaller screens */
}

.logo {
  float: none;
  padding: 10px 0;
  text-align: center;
  font-size: 20px;
  margin-right: 70rem;
}

.nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: flex;
  justify-content: space-around; /* Adjust alignment for better spacing on smaller screens */
  flex-wrap: wrap; /* Allow items to wrap to the next line */
}

.nav-links li {
  margin: 10px 0;
}

.right {
  margin-left: 0;
}

.nav-links li a {
  display: block;
  color: rgb(13, 12, 12);
  text-align: center;
  padding: 10px; /* Adjust padding for better spacing on smaller screens */
  text-decoration: none;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #121111;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 160px;
  border-radius: 5px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.card-container1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
  padding: 20px; /* Add padding for better spacing on smaller screens */
}

.card1 {
  width: 100%; /* Adjust card width for smaller screens */
  max-width: 300px; /* Set a max-width for better responsiveness */
  margin: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Add media queries for responsiveness */
@media screen and (max-width: 768px) {
  .nav-links {
    justify-content: center;
  }

  .nav-links li {
    margin: 10px;
  }

  .logo {
    margin-right: 0;
  }

  .dropdown-content {
    right: 0;
  }

  .card1 {
    width: 100%; /* Adjust card width for smaller screens */
    margin: 10px 0;
  }
}

.complementary-section {
  background-color: #f5f5f5;
  padding: 40px;
  text-align: center;
}

.complementary-section h2 {
  margin-bottom: 20px;
  position: relative;
  top: -33px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
  position: relative;
  top: -33px;
}

.card {
  flex: 1;
  width: 100%; /* Adjust width for smaller screens */
  max-width: 300px; /* Set a max-width for better responsiveness */
  background-color: white;
  padding: 20px;
  margin: 0 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 20px;
}

/* Add media queries for responsiveness */
@media screen and (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }
}
