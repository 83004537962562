/* src/components/CountingSection.css */

.counting-section {
  background-color: #f08a0e;
  padding: 40px;
  text-align: center;
  position: relative;
  top: -29px;
}

.counting-card-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap; /* Allow cards to wrap on smaller screens */
}

.counting-card {
  background-color: #f9f9f7;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-size: 24px;
  font-weight: bold;
  text-align: center; /* Center text on smaller screens */
  width: calc(45% - 20px); /* Adjust card width for smaller screens with some spacing */
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
  .counting-card-container {
    flex-direction: column; /* Stack cards vertically on smaller screens */
  }

  .counting-card {
    width: 100%; /* Make cards full width on smaller screens */
  }
}
