/* src/components/AstrologyNewsSection.css */
.astrology-news-section {
    background-color: #f78706; /* Light gray background */
    padding: 40px;
    text-align: center;
  }
  
  .news-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .news-card {
    background-color: white;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1; /* Equal width for each card */
  }
  
  .news-card h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .news-card p {
    font-size: 14px;
    margin: 0;
  }
  