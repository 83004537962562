/* src/components/CelebrityCustomerSection.css */

.celebrity-customer-section {
  background-color: #f2f2eb;
  padding: 40px;
  text-align: center;
}

.video-slider {
  position: relative;
  overflow: hidden; /* Ensure overflow is hidden for slider */
}

.video-cards-container {
  display: flex;
  flex-direction: column; /* Stack items vertically on smaller screens */
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.video-cards {
  display: flex;
  flex-wrap: nowrap; /* Ensure cards are in a single row */
  overflow-x: auto; /* Allow horizontal scrolling on smaller screens */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  scrollbar-width: thin; /* Adjust scrollbar width as needed */
}

.video-card {
  flex: 0 0 auto;
  width: 100%;
  margin-right: 20px; /* Add margin between videos */
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.video-card.active {
  opacity: 1;
}

.slide-controls {
  position: absolute;
  top: 50%;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.slide-controls.left {
  left: 10px;
}

.slide-controls.right {
  right: 10px;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
  .video-cards {
    flex-wrap: wrap;
    justify-content: center;
    overflow-x: hidden; /* Disable horizontal scrolling on smaller screens */
  }

  .video-card {
    margin-bottom: 20px; /* Add margin between videos on smaller screens */
  }
}
