/* src/components/CelebritySection.css */

.page-container {
  background-color: rgb(40, 39, 39);
  padding: 20px;
  border-radius: 5px;
}

.celebrity-section {
  background-color: rgb(252, 174, 6);
  padding: 20px;
}

.celebrity-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.celebrity-image {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
  margin-bottom: 15px;
}

.celebrity-text {
  max-width: 100%;
  text-align: center;
}

.chat-now-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 70px;
}

.card {
  flex: 1;
  background-color: white;
  padding: 20px;
  margin: 0 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 100%;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
  .celebrity-content {
    text-align: center;
  }

  .celebrity-text {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .card {
    width: calc(50% - 20px); /* Adjust card width for smaller screens with some spacing */
  }
}
