/* src/components/WhyAstrologySection.css */

.why-astrology-section {
  background-color: #f8f8f8;
  padding: 40px;
  text-align: left;
}

.why-astrology-section h2 {
  color: #333;
}

.why-astrology-section p {
  font-size: 16px;
  margin-bottom: 20px;
}

.highlighted-points {
  margin-top: 20px;
}

.highlight {
  font-weight: bold;
  color: #ff9900;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
  .why-astrology-section {
    text-align: center;
  }

  .why-astrology-section h2 {
    font-size: 24px; /* Adjust heading font size for smaller screens */
  }

  .why-astrology-section p {
    font-size: 14px; /* Adjust paragraph font size for smaller screens */
  }
}
